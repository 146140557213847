import React from 'react'
import { Controller } from 'react-hook-form'
import { Box, TextField, Switch, CircularProgress, FormControl, FormLabel, Select, MenuItem } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import emailMask from 'text-mask-addons/dist/emailMask'

import { stripDiacritics } from 'utils/stripDiacritics'
import { isUndefined } from 'lodash'

export const BookingServices = [
  { NonIntegrated: '10' },
  { Mbo: '20' },
  { Mba: '30' },
  { InShape: '50' },
  { LaFitness: '60' },
  { AnytimeFitness: '61' },
  { FlexNetwork: '100' },
]

export const ExternalLocationIDBookingServices = ['60', '61']

export const InvoiceTypes = [
  { 'PmPm Plus Usage': '1' },
  { 'Legacy Disbursement': '2' },
  { Unlimited: '3' },
  { 'PmPm Fully Insured': '4' },
  { Digital: '5' },
]

export const InvoiceTypesOptions = InvoiceTypes.map((invoice) => {
  const [key] = Object.keys(invoice)
  return { label: key, value: invoice[key] }
})

export const Networks = [
  { Core: '1' },
  { Move: '2' },
  { Qa_Test_Network: '3' },
  { Alignment_Health_Employees: '4' },
  { Core_Digital: '5' },
  { Core_Select: '6' },
]

export const NetworkOptions = Networks.map((network) => {
  const [key] = Object.keys(network)
  return { label: key, value: network[key] }
})

export const SponsorTypes = [{ 'Sponsors Core Users': '1' }, { 'Sponsors Move Users': '2' }]

export const SponsorTypeOptions = SponsorTypes.map((sponsorType) => {
  const [key] = Object.keys(sponsorType)
  return { label: key, value: sponsorType[key] }
})

export const BookingServicesOptions = BookingServices.map((service) => {
  const [key] = Object.keys(service)
  return { label: key, value: service[key] }
})

export const SponsorPaymentModel = [
  { D2C: 0 },
  { 'PMPM + Usage': 1 },
  { 'Legacy Disbursement': 2 },
  { 'Tiered PMPM': 3 },
  { 'Fixed PMPM': 4 },
  { Digital: 5 },
  { 'Dummy or Test': 6 },
]

export const SponsorPaymentModelOptions = SponsorPaymentModel.map((model) => {
  const [key] = Object.keys(model)
  return { label: key, value: model[key] }
})

const currencyMask = (rawValue) => {
  const numberMask = createNumberMask({
    prefix: '$',
    includeThousandsSeparator: true,
    allowDecimal: true,
    requireDecimal: true,
    allowLeadingZeroes: false,
  })
  const mask = numberMask(rawValue)
  const decimalsRegex = /\.([0-9]{1,2})/
  const result = decimalsRegex.exec(rawValue)

  // In case there is only one decimal
  if (result?.[1].length < 2) {
    mask.push('0')
  } else if (!result) {
    mask.push('0')
    mask.push('0')
  }

  return mask
}

const filterOptions = (options, value) => {
  const lower = value?.toLowerCase()
  return options?.filter((option) => {
    const candidate = stripDiacritics(option?.name || '')?.toLowerCase()
    const candidateId = option?.id
    return candidate.includes(lower) || candidateId === lower
  })
}

export const EmailMaskInput = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={emailMask}
      guide={false}
      showMask
    />
  )
}

export const CurrencyMaskInput = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={currencyMask}
      guide={false}
      showMask
    />
  )
}

export const DecimalMaskInput = (props) => {
  const { inputRef, allowDecimal = true, allowLeadingZeroes = false, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal,
        allowLeadingZeroes,
      })}
      guide={false}
      showMask
    />
  )
}

export const EmailField = React.forwardRef((props, ref) => (
  <TextField
    ref={ref}
    {...props}
    InputProps={{
      inputComponent: EmailMaskInput,
    }}
  />
))

export const CurrencyField = React.forwardRef((props, ref) => {
  return (
    <TextField
      ref={ref}
      {...props}
      InputProps={{
        inputComponent: CurrencyMaskInput,
        onChange: (event) => {
          props.onChange(Number(event.target.value?.replace(/[^0-9.-]+/g, '')))
        },
        value: props.value,
      }}
    />
  )
})

export const DecimalField = React.forwardRef((props, ref) => (
  <TextField
    ref={ref}
    {...props}
    InputProps={{
      inputComponent: DecimalMaskInput,
    }}
  />
))

const getInputFieldByMaskType = (mask) => {
  switch (mask) {
    case 'currency':
      return CurrencyField
    case 'decimal':
      return DecimalField
    case 'email':
      return EmailField
    default:
      return TextField
  }
}

export const TextInputBox = React.memo(
  ({
    control,
    name,
    label,
    type = 'text',
    required,
    errors,
    mask,
    rules,
    disabled,
    defaultValue = '',
    decimalInputProps = {},
  }) => {
    return (
      <Box mb={2}>
        <Controller
          control={control}
          as={getInputFieldByMaskType(mask)}
          label={label}
          name={name}
          type={type}
          {...(required && { required, error: !!errors?.[name], rules: rules ? {} : { required: true } })}
          {...(rules && {
            error: !!errors?.[name],
            rules,
          })}
          fullWidth
          variant="outlined"
          defaultValue={defaultValue}
          disabled={disabled}
          {...(mask === 'decimal' && {
            inputProps: { ...decimalInputProps },
          })}
        />
      </Box>
    )
  }
)

export const CheckBox = React.memo(
  ({ control, name, label, type = 'text', required, errors, value, defaultValue = false, isReverse }) => {
    return (
      <Box mb={2} ml={1}>
        <Controller
          control={control}
          name={name}
          type={type}
          {...(required && { required, error: !!errors?.[name], rules: { required: true } })}
          variant="outlined"
          defaultValue={defaultValue}
          render={({ onChange, onBlur, value, ref }) => (
            <FormControl required={required} error={!!errors?.[name]} component="fieldset">
              <Box display="flex" alignItems="center">
                <FormLabel component="label" required={required} error={!!errors?.[name]}>
                  {label}
                </FormLabel>
                <Switch
                  onChange={(e) => onChange(isReverse ? !e.target.checked : e.target.checked)}
                  inputRef={ref}
                  checked={isReverse ? !value : !!value}
                />
              </Box>
            </FormControl>
          )}
        />
      </Box>
    )
  }
)

export const TextAreaBox = React.memo(({ control, name, label, required, errors, rows = 4 }) => (
  <Box mb={2}>
    <Controller
      control={control}
      as={TextField}
      label={label}
      name={name}
      {...(required && { required, error: !!errors?.[name], rules: { required: true } })}
      fullWidth
      variant="outlined"
      defaultValue=""
      multiline
      minRows={rows}
    />
  </Box>
))

export const SelectInputBox = React.memo(
  ({ control, name, label, required, errors, options, onChange, value, defaultValue }) => {
    const [currentValue, setCurrentValue] = React.useState(value)

    const handleChange = (value) => {
      setCurrentValue(value)
      onChange && onChange(value)
    }

    return (
      <Box mb={2}>
        <Controller
          control={control}
          as={
            <TextField>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          }
          label={label}
          name={name}
          {...(required && { required, error: !!errors?.[name], rules: { required: true } })}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          select
          SelectProps={{ native: true }}
          value={currentValue}
          defaultValue={defaultValue || options?.[0]?.value || ''}
        />
      </Box>
    )
  }
)

export const DropdownSelectBox = React.memo(
  ({ control, name, label, required, errors, options, onChange, value, defaultValue }) => {
    const [currentValue, setCurrentValue] = React.useState(value)

    const handleChange = (value) => {
      setCurrentValue(value)
      onChange && onChange(value)
    }

    return (
      <Box mb={2}>
        <Controller
          control={control}
          as={
            <TextField>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          }
          label={label}
          name={name}
          {...(required && { required, error: !!errors?.[name], rules: { required: true } })}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          select
          value={currentValue}
          defaultValue={defaultValue || ''}
        />
      </Box>
    )
  }
)

export const DatePickerBox = React.memo(
  ({ control, name, label, required, format = 'MM/dd/yyyy', onChange, errors, minDate, maxDate }) => {
    return (
      <Box mb={2}>
        <Controller
          control={control}
          name={name}
          {...(required && { required, error: !!errors?.[name], rules: { required: true } })}
          render={({ ...props }) => (
            <KeyboardDatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label={label}
              format={format}
              fullWidth
              error={!!errors?.[name]}
              helperText={errors?.[name]?.message}
              required={required}
              InputAdornmentProps={{ position: 'end' }}
              value={props.value || null}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(date) => {
                if (!!date && !isNaN(Date.parse(date))) {
                  const isoDate = new Date(date).toISOString()
                  props.onChange(isoDate)
                  onChange && onChange(isoDate)
                } else {
                  props.onChange(date)
                }
              }}
            />
          )}
        />
      </Box>
    )
  }
)

export const DateTimePickerBox = React.memo(
  ({ control, name, label, required, format = 'MM/dd/yyyy (h:mm aa)', onChange, errors }) => {
    return (
      <Box mb={2}>
        <Controller
          control={control}
          name={name}
          {...(required && { required, error: !!errors?.[name], rules: { required: true } })}
          render={({ ...props }) => (
            <KeyboardDateTimePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label={label}
              format={format}
              fullWidth
              error={!!errors?.[name]}
              helperText={errors?.[name]?.message}
              required={required}
              InputAdornmentProps={{ position: 'end' }}
              value={props.value || null}
              onChange={(date) => {
                if (!!date && !isNaN(Date.parse(date))) {
                  const isoDate = new Date(date).toISOString()
                  props.onChange(isoDate)
                  onChange && onChange(isoDate)
                } else {
                  props.onChange(date)
                }
              }}
            />
          )}
        />
      </Box>
    )
  }
)

export const AutoCompleteBox = React.memo(
  ({
    control,
    name,
    label,
    required,
    errors,
    defaultValue = null,
    onInputChange,
    onValueChange,
    onResultsChange,
    onOpen,
    onClose,
    options,
    isLoading,
    open,
    multiple,
    renderTags,
  }) => {
    const currentInputValueRef = React.useRef('')

    return (
      <Box mb={2}>
        <Controller
          control={control}
          name={name}
          {...(required && { rules: { required: true } })}
          render={({ onChange, value, ...props }) => {
            const item = options?.find((opt) => (opt?.id ?? opt) === (value?.id ?? value))
            if (isUndefined(value?.id) && item) {
              onChange(item)
            }
            return (
              <Autocomplete
                fullWidth
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                getOptionSelected={(option, value) => {
                  return (option?.id ?? option) === (value.id ?? value)
                }}
                getOptionLabel={(option) =>
                  option?.name ?? options?.find((opt) => (opt?.id ?? opt) === (option?.id ?? option))?.name ?? ''
                }
                value={value}
                options={options || []}
                loading={isLoading}
                onChange={(e, data) => {
                  onChange(data)
                  onValueChange && onValueChange(data)
                }}
                onInputChange={onInputChange}
                filterOptions={(options, { inputValue }) => {
                  console.log(options)
                  const results = filterOptions(options, inputValue)
                  if (inputValue !== currentInputValueRef.current) {
                    onResultsChange && onResultsChange(results, inputValue)
                    currentInputValueRef.current = inputValue
                  }
                  return results
                }}
                multiple={multiple}
                {...(renderTags && { renderTags })}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label={label}
                      variant="outlined"
                      {...(required && { required, error: !!errors?.[name] })}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )
                }}
              />
            )
          }}
        />
      </Box>
    )
  }
)

export const AutoCompleteBoxForStudio = ({
  label,
  isLoading,
  options,
  onValueChange,
  handleStudioChange,
  control,
  open,
  onOpen,
  onClose,
  multiple,
  onScrollToBottom = () => {},
}) => {
  return (
    <Controller
      defaultValue={null}
      render={({ onChange, value, ...props }) => (
        <Autocomplete
          multiple={multiple}
          ListboxProps={{
            onScroll: (e) => {
              const listboxNode = e.currentTarget
              if (listboxNode.scrollTop + listboxNode.clientHeight + 16 >= listboxNode.scrollHeight) {
                onScrollToBottom()
              }
            },
          }}
          clearOnBlur={false}
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          options={options}
          defaultValue={null}
          getOptionSelected={(option, value) => option.name === value.name}
          onInputChange={onValueChange}
          getOptionLabel={(option) => option.name}
          filterOptions={(options) => options}
          onChange={(e, data) => {
            handleStudioChange && handleStudioChange(data)
          }}
          renderOption={(option, props) => (
            <>
              <span>{option.name}</span>
            </>
          )}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                onChange={(e, data) => {
                  handleStudioChange && handleStudioChange(data)
                  return data
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )
          }}
        />
      )}
      name="studio"
      control={control}
    />
  )
}

export const postalCodeRegex = /^\d{5}(-\d{4})?$/
