import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutoCompleteBox } from 'helpers/forms'

export const defaultNetworkOptions = [
  { name: 'Core', id: 1 },
  { name: 'Move', id: 2 },
  { name: 'Core Digital', id: 5 },
  { name: 'Core Select', id: 6 },
  { name: 'Digital only Excluding InPerson', id: 8 },
]

export const NetworksAutocompleteBox = React.memo(
  ({ control, options = defaultNetworkOptions, name, label, required, onValueChange, handleChange, errors }) => {
    const { t } = useTranslation()

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    const getValue = () => {
      const value =
        control.getValues()?.[name]?.map((item) => {
          return item.id ? item : { id: item }
        }) || []

      return value
    }

    return (
      <AutoCompleteBox
        control={control}
        errors={errors}
        name={name}
        label={label || t('common.form_labels.networks')}
        required={required}
        options={options}
        value={getValue()}
        onValueChange={onValueChange}
        handleChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        multiple
      />
    )
  }
)
