import React, { lazy } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { AuthProvider } from 'authProvider'
import { RoutePaths } from 'constants/globals'
import { DashboardLayout } from 'layouts'

const NotFoundView = lazy(() => import('containers/Errors/NotFoundView'))
const LoginScreen = lazy(() => import('containers/Login'))
const BrandsScreen = lazy(() => import('containers/Brands'))
const BrandDetailsScreen = lazy(() => import('containers/BrandDetails'))
const BudgetsScreen = lazy(() => import('containers/Budgets'))
const BudgetDetailsScreen = lazy(() => import('containers/BudgetDetails'))
const FeatureFlagsScreen = lazy(() => import('containers/FeatureFlags'))
const FeatureFlagsDetailsScreen = lazy(() => import('containers/FeatureFlagDetails'))
const StudiosBulkUploadScreen = lazy(() => import('containers/BulkUpload'))
const RegionsScreen = lazy(() => import('containers/Regions'))
const RegionDetailsScreen = lazy(() => import('containers/RegionDetails'))
const ReservationsScreen = lazy(() => import('containers/Reservations'))
const ReservationDetailsScreen = lazy(() => import('containers/ReservationDetails'))
const SponsorsScreen = lazy(() => import('containers/Sponsors'))
const PromotionsScreen = lazy(() => import('containers/Promotions'))
const PromotionDetailsScreen = lazy(() => import('containers/PromotionDetails'))
const SponsorDetailsScreen = lazy(() => import('containers/SponsorDetails'))
const UsersScreen = lazy(() => import('containers/Users'))
const UserDetailsScreen = lazy(() => import('containers/UserDetails'))
const StudiosScreen = lazy(() => import('containers/Studios'))
const StudioDetailsScreen = lazy(() => import('containers/StudioDetails'))
const StudioGroupsScreen = lazy(() => import('containers/StudioGroups'))
const StudioGroupDetailsScreen = lazy(() => import('containers/StudioGroupDetails'))
const StoreItemsScreen = lazy(() => import('containers/StoreItems'))
const StoreItemDetailsScreen = lazy(() => import('containers/StoreItemDetails'))
const TransactionsScreen = lazy(() => import('containers/Transactions'))
const TransactionDetailsScreen = lazy(() => import('containers/TransactionDetails'))
const MBOSiteDetailsScreen = lazy(() => import('containers/MBOSiteDetails'))
const ReactivateMembersScreen = lazy(() => import('containers/BulkUpload/ReactivateMembers'))
const GooglePlaceIDsScreen = lazy(() => import('containers/GooglePlaceIDs'))

const Routes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth)

  return (
    <AuthProvider>
      {isAuthenticated ? (
        <Router>
          <DashboardLayout>
            <Switch>
              <Route exact path={RoutePaths.HOME} render={() => <Redirect to={RoutePaths.USERS} />} />
              <Route exact path={RoutePaths.LOGIN} render={(props) => <LoginScreen showHeader={false} {...props} />} />
              <Route exact path={RoutePaths.LOGOUT} component={LoginScreen} />
              <Route exact path={RoutePaths.DASHBOARD} component={LoginScreen} />
              <Route exact path={RoutePaths.BRANDS} component={BrandsScreen} />
              <Route exact path={RoutePaths.BRANDS_DETAILS} component={BrandDetailsScreen} />
              <Route exact path={RoutePaths.BUDGETS} component={BudgetsScreen} />
              <Route exact path={RoutePaths.BUDGET_DETAILS} component={BudgetDetailsScreen} />
              <Route exact path={RoutePaths.FEATURE_FLAGS} component={FeatureFlagsScreen} />
              <Route exact path={RoutePaths.FEATURE_FLAG_DETAIL} component={FeatureFlagsDetailsScreen} />
              <Route exact path={RoutePaths.STUDIOS_BULK_UPLOAD} component={StudiosBulkUploadScreen} />
              <Route exact path={RoutePaths.REACTIVATE_MEMBERS} component={ReactivateMembersScreen} />
              <Route exact path={RoutePaths.REGIONS} component={RegionsScreen} />
              <Route exact path={RoutePaths.REGIONS_DETAILS} component={RegionDetailsScreen} />
              <Route exact path={RoutePaths.RESERVATIONS} component={ReservationsScreen} />
              <Route exact path={RoutePaths.RESERVATIONS_DETAILS} component={ReservationDetailsScreen} />
              <Route exact path={RoutePaths.SPONSORS} component={SponsorsScreen} />
              <Route exact path={RoutePaths.SPONSORS_DETAILS} component={SponsorDetailsScreen} />
              <Route exact path={RoutePaths.STUDIOS} component={StudiosScreen} />
              <Route exact path={RoutePaths.STUDIOS_DETAILS} component={StudioDetailsScreen} />
              <Route exact path={RoutePaths.STUDIO_GROUPS} component={StudioGroupsScreen} />
              <Route exact path={RoutePaths.STUDIO_GROUPS_DETAILS} component={StudioGroupDetailsScreen} />
              <Route
                exact
                path={RoutePaths.STUDIO_GROUPS_DETAILS + RoutePaths.MBO_SITE_DETAILS}
                component={MBOSiteDetailsScreen}
              />
              <Route exact path={RoutePaths.STORE_ITEMS} component={StoreItemsScreen} />
              <Route exact path={RoutePaths.STORE_ITEM_DETAIL} component={StoreItemDetailsScreen} />
              <Route exact path={RoutePaths.TRANSACTIONS} component={TransactionsScreen} />
              <Route exact path={RoutePaths.TRANSACTIONS_DETAILS} component={TransactionDetailsScreen} />
              <Route exact path={RoutePaths.USERS} component={UsersScreen} />
              <Route exact path={RoutePaths.PROMOCODES} component={PromotionsScreen} />
              <Route exact path={RoutePaths.PROMOCODES_DETAILS} component={PromotionDetailsScreen} />
              <Route exact path={RoutePaths.USERS_DETAILS} component={UserDetailsScreen} />
              <Route exact path={RoutePaths.GOOGLE_PLACE_IDS} component={GooglePlaceIDsScreen} />
              <Route exact path={RoutePaths.USERS_EDIT} component={UserDetailsScreen} />
              <Route path={RoutePaths.NOT_FOUNDED} component={NotFoundView} />
              <Route component={NotFoundView} />
            </Switch>
          </DashboardLayout>
        </Router>
      ) : (
        <LoginScreen />
      )}
    </AuthProvider>
  )
}

export default Routes
