import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { InputAdornment, SvgIcon, TextField, makeStyles } from '@material-ui/core'
import { Search as SearchIcon, XCircle as ClearIcon } from 'react-feather'

import { usePrevious } from 'hooks/usePrevious'
import { useDebounce } from 'hooks/useDebounce'

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: 300,
  },
}))

const SearchField = ({ label, value, onSearch, onClear, ...props }) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState(value || '')
  const debouncedSearchValue = useDebounce(searchValue, 250)
  const prevDebouncedSearchValue = usePrevious(debouncedSearchValue)

  const handleQueryChange = (e) => {
    setSearchValue(e.target.value)
  }

  const handleClearSearch = (e) => {
    setSearchValue('')
    onClear && onClear()
  }

  useEffect(() => {
    debouncedSearchValue !== prevDebouncedSearchValue &&
      !isEmpty(debouncedSearchValue) &&
      onSearch(debouncedSearchValue)

    // Clears/Resets the search
    !isEmpty(prevDebouncedSearchValue) && isEmpty(debouncedSearchValue) && onSearch(debouncedSearchValue)
  }, [debouncedSearchValue, prevDebouncedSearchValue, onSearch])

  return (
    <TextField
      className={classes.searchField}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon fontSize="small" color="action">
              <SearchIcon />
            </SvgIcon>
          </InputAdornment>
        ),
        endAdornment: !isEmpty(searchValue) && (
          <InputAdornment position="start">
            <SvgIcon fontSize="small" color="action" cursor="pointer" onClick={handleClearSearch}>
              <ClearIcon />
            </SvgIcon>
          </InputAdornment>
        ),
      }}
      onChange={handleQueryChange}
      placeholder={label}
      value={searchValue}
      variant="outlined"
    />
  )
}

export default SearchField
