import { createSlice } from '@reduxjs/toolkit'

import {
  fetchUserById,
  fetchUserMemberships,
  fetchUserSubscriptions,
  fetchUserSponsors,
  fetchUserStripeCards,
  fetchUserFlexCards,
  fetchUserReservations,
  fetchUserActivities,
  fetchUserTransactions,
  putUserById,
  fetchFitkitsAvailableForPurchase,
} from 'store/actions/services.actions'

const SAVING = 'SAVING'
const SAVED = 'SAVED'
const FAILED = 'FAILED'

export const STATUS = { SAVING, SAVED, FAILED }

const initialState = {
  isLoading: false,
  status: null,
  errors: null,
  user: null,
  userMemberships: {
    isLoading: false,
    status: null,
    errors: null,
  },
  userReservations: {
    isLoading: false,
    status: null,
    errors: null,
  },
  userActivities: {
    isLoading: false,
    status: null,
    errors: null,
  },
  userSponsors: {
    isLoading: false,
    status: null,
    errors: null,
  },
  userSubscriptions: {
    isLoading: false,
    status: null,
    errors: null,
  },
  userStripeCards: {
    isLoading: false,
    status: null,
    errors: null,
  },
  userFlexCards: {
    isLoading: false,
    status: null,
    errors: null,
  },
  userTransactions: {
    isLoading: false,
    status: null,
    errors: null,
  },
  users: {
    isLoading: false,
    status: null,
    errors: null,
  },
  fitkitsAvailableForPurchase: {
    isLoading: false,
    status: null,
    errors: null,
  },
}

const userDetailsExtraReducers = {
  [`${fetchUserById.pending}`]: (state, action) => ({ ...state, isLoading: true, errors: null }),
  [`${putUserById.pending}`]: (state, action) => {
    const { params } = action.meta.arg
    // Optimistic update
    return { ...state, isLoading: false, status: STATUS.SAVING, errors: null, user: { ...state.user, ...params } }
  },

  [`${fetchUserById.rejected}`]: (state, action) => ({ ...state, isLoading: false, errors: action?.error?.message }),
  [`${putUserById.rejected}`]: (state, action) => {
    return {
      ...state,
      isLoading: false,
      status: STATUS.FAILED,
      errors: action?.error?.message,
    }
  },

  [`${fetchUserById.fulfilled}`]: (state, action) => {
    return { ...state, isLoading: false, user: { ...action.payload } }
  },
  [`${putUserById.fulfilled}`]: (state, action) => ({
    ...state,
    isLoading: false,
    status: STATUS.SAVED,
    errors: null,
  }),
}

const userMembershipsExtraReducers = {
  [`${fetchUserMemberships.pending}`]: (state, action) => ({
    ...state,
    userMemberships: { isLoading: true, errors: null },
  }),
  [`${fetchUserMemberships.rejected}`]: (state, action) => ({
    ...state,
    userMemberships: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchUserMemberships.fulfilled}`]: (state, action) => {
    return { ...state, userMemberships: { isLoading: false, errors: null, ...action.payload } }
  },
}

const userSponsorsExtraReducers = {
  [`${fetchUserSponsors.pending}`]: (state, action) => ({ ...state, userSponsors: { isLoading: true, errors: null } }),
  [`${fetchUserSponsors.rejected}`]: (state, action) => ({
    ...state,
    userSponsors: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchUserSponsors.fulfilled}`]: (state, action) => {
    return { ...state, userSponsors: { isLoading: false, errors: null, ...action.payload } }
  },
}

const userSubscriptionsExtraReducers = {
  [`${fetchUserSubscriptions.pending}`]: (state, action) => ({
    ...state,
    userSubscriptions: { isLoading: true, errors: null },
  }),
  [`${fetchUserSubscriptions.rejected}`]: (state, action) => ({
    ...state,
    userSubscriptions: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchUserSubscriptions.fulfilled}`]: (state, action) => {
    return { ...state, userSubscriptions: { isLoading: false, errors: null, ...action.payload } }
  },
}

const userStripeCardsExtraReducers = {
  [`${fetchUserStripeCards.pending}`]: (state, action) => ({
    ...state,
    userStripeCards: { isLoading: true, errors: null },
  }),
  [`${fetchUserStripeCards.rejected}`]: (state, action) => ({
    ...state,
    userStripeCards: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchUserStripeCards.fulfilled}`]: (state, action) => {
    return { ...state, userStripeCards: { isLoading: false, errors: null, ...action.payload } }
  },
}

const userFlexCardsExtraReducers = {
  [`${fetchUserFlexCards.pending}`]: (state, action) => ({
    ...state,
    userFlexCards: { isLoading: true, errors: null },
  }),
  [`${fetchUserFlexCards.rejected}`]: (state, action) => ({
    ...state,
    userFlexCards: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchUserFlexCards.fulfilled}`]: (state, action) => {
    return { ...state, userFlexCards: { isLoading: false, errors: null, ...action.payload } }
  },
}

const userReservationsExtraReducers = {
  [`${fetchUserReservations.pending}`]: (state, action) => ({
    ...state,
    userReservations: { isLoading: true, errors: null },
  }),
  [`${fetchUserReservations.rejected}`]: (state, action) => ({
    ...state,
    userReservations: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchUserReservations.fulfilled}`]: (state, action) => {
    return { ...state, userReservations: { isLoading: false, errors: null, ...action.payload } }
  },
}

const userActivitiesExtraReducers = {
  [`${fetchUserActivities.pending}`]: (state, action) => ({
    ...state,
    userActivities: { isLoading: true, errors: null },
  }),
  [`${fetchUserActivities.rejected}`]: (state, action) => ({
    ...state,
    userActivities: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchUserActivities.fulfilled}`]: (state, action) => {
    return { ...state, userActivities: { isLoading: false, errors: null, ...action.payload } }
  },
}

const userTransactionsExtraReducers = {
  [`${fetchUserTransactions.pending}`]: (state, action) => ({
    ...state,
    userTransactions: { isLoading: true, errors: null },
  }),
  [`${fetchUserTransactions.rejected}`]: (state, action) => ({
    ...state,
    userTransactions: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchUserTransactions.fulfilled}`]: (state, action) => {
    return { ...state, userTransactions: { isLoading: false, errors: null, ...action.payload } }
  },
}

const userFitkitsAvailableForPurchaseExtraReducers = {
  [`${fetchFitkitsAvailableForPurchase.pending}`]: (state, action) => ({
    ...state,
    fitkitsAvailableForPurchase: { isLoading: true, errors: null },
  }),
  [`${fetchFitkitsAvailableForPurchase.rejected}`]: (state, action) => ({
    ...state,
    fitkitsAvailableForPurchase: { isLoading: false, errors: action?.error?.message },
  }),
  [`${fetchFitkitsAvailableForPurchase.fulfilled}`]: (state, action) => {
    return { ...state, fitkitsAvailableForPurchase: { isLoading: false, errors: null, results: [...action.payload] } }
  },
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    clearUser: (state) => ({ ...state, user: null }),
    clearAll: (state) => initialState,
  },
  extraReducers: {
    ...userDetailsExtraReducers,
    ...userMembershipsExtraReducers,
    ...userSponsorsExtraReducers,
    ...userSubscriptionsExtraReducers,
    ...userStripeCardsExtraReducers,
    ...userFlexCardsExtraReducers,
    ...userReservationsExtraReducers,
    ...userActivitiesExtraReducers,
    ...userTransactionsExtraReducers,
    ...userFitkitsAvailableForPurchaseExtraReducers,
  },
})

export const usersActions = usersSlice.actions

export default usersSlice.reducer
