import { Box, Divider, Drawer, Hidden, List, ListSubheader, makeStyles, Typography } from '@material-ui/core'
import { Logo } from 'components'
import { usePermission } from 'hooks/usePermission'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { Globe as GlobeIcon, Users as UsersIcon, Flag as FlagIcon, ShoppingBag as StoreItemIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { AiOutlineCalculator as BudgetIcon, AiOutlineTags as PromotionsIcon } from 'react-icons/ai'
import { CgArrowsExchangeAlt as TransactionIcon } from 'react-icons/cg'
import { FaRegHandshake as HandShakeIcon } from 'react-icons/fa'
import { GiStarsStack as BrandsIcon } from 'react-icons/gi'
import { ImMakeGroup as StudioGroupIcon } from 'react-icons/im'
import {
  RiCalendarCheckLine as ReservationIcon,
  RiStore3Line as StudiosIcon,
  RiGoogleFill as GooglePlaceIdIcon,
} from 'react-icons/ri'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom'
import NavItem from './NavItem'

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  )
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    )
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />)
  }

  return acc
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  navItemsList: {
    '& .MuiListItem-root': {
      marginBottom: theme.spacing(1),
    },
  },
}))

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const location = useLocation()
  const { t } = useTranslation()
  const canAccess = usePermission()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const NavbarRoutes = useMemo(() => {
    const config = [
      {
        items: [
          {
            title: t('navbar.users.label'),
            icon: UsersIcon,
            href: '/users',
          },
          {
            title: t('navbar.regions.label'),
            icon: GlobeIcon,
            href: '/regions',
          },
          {
            title: t('navbar.sponsors.label'),
            icon: HandShakeIcon,
            href: '/sponsors',
          },
          {
            title: t('navbar.brands.label'),
            icon: BrandsIcon,
            href: '/brands',
          },
          {
            title: t('navbar.fitness_groups.label'),
            icon: StudioGroupIcon,
            href: '/studio-groups',
          },
          {
            title: t('navbar.fitness_locations.label'),
            icon: StudiosIcon,
            href: '/studios',
          },
          {
            title: t('navbar.google_place_id.label'),
            icon: GooglePlaceIdIcon,
            href: '/google-place-ids',
          },
          {
            title: t('navbar.reservations.label'),
            icon: ReservationIcon,
            href: '/reservations',
          },
          {
            title: t('navbar.promotions.label'),
            icon: PromotionsIcon,
            href: '/promotions',
          },
          {
            title: t('navbar.budgets.label'),
            icon: BudgetIcon,
            href: '/budgets',
          },
          {
            title: t('navbar.transactions.label'),
            icon: TransactionIcon,
            href: '/transactions',
          },
          // {
          //   title: t('navbar.uploads.label'),
          //   icon: UploadCloudIcon,
          //   href: '/uploads',
          // },
        ],
      },
    ]
    if (canAccess.featureFlags) {
      config[0].items.push({
        title: t('navbar.feature_flags.label'),
        icon: FlagIcon,
        href: '/feature-flags',
      })
    }
    if (canAccess.storeItems) {
      config[0].items.push({
        title: t('navbar.store_items.label'),
        icon: StoreItemIcon,
        href: '/store-items',
      })
    }
    return config
  }, [t, canAccess])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="start">
            <RouterLink to="/">
              <Logo height={30} />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {NavbarRoutes.map((section, index) => (
            <List
              key={`subheader-${index}`}
              className={classes.navItemsList}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" display="flex" justifyContent="center" bgcolor="background.dark">
            <Typography variant="caption" align="center" color="textPrimary">
              {process.env.REACT_APP_VERSION}
            </Typography>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
